.confirmation-modal {
  & .ant-modal-title {
    font-size: $fontSize-lg;
  }

  & .ant-modal-body {
    height: 100px;

    & .ant-spin-nested-loading {
      width: 100%;
      height: 100%;
    }

    & .ant-spin-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      gap: 16px;
    }
  }
}