.technician-schedule {
  & .ant-table-tbody {
    & .ant-table-row {
      & .ant-table-cell {
        min-width: 152px;
        min-height: 165px;
        justify-content: space-between;

        &.day_off {
          background: $color-white !important;
        }
    
        &.day {
          background: $color-cosmic-latte !important;
        }
    
        &.night {
          background: $color-ghost-white !important;
        }
      }
    }
  }

  &__header {
    justify-content: space-between;

    &_technician-filter {
      display: flex;
      align-items: center;
      gap: 16px;
      color: $color-slate-gray;

      & .ant-select {
        width: 236px;
      }
    }
  }

  &__footer {
    height: 56px;
    display: flex;
    align-items: flex-end;
    gap: 16px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;

    &-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      color: $color-slate-gray;
    }

    & .ant-form-item {
      width: 100%;
      margin-bottom: 0;
    }

    & .ant-form-item-explain {
      display: none;
    }

    & .ant-col:hover .ant-form-item-explain {
      display: block;
      position: absolute;
      bottom: 36px;
      left: 0;
      padding: 4px 8px;
      background: $color-white;
      border: 1px solid $color-pale-aqua;
      border-radius: 6px;
    }
  }

  &__location-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &_name {
      padding: 6px 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-glaucous;
      border-radius: 6px;
      color: $color-white;
    }
  }
}
