.container {
  width: 100%;
  padding: 0 48px;

  &__form {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.search-input-suffix {
  width: 24px;
  height: 24px;
  background-image: url(../../images/search-icon.svg);
}

.btn {
  &-icon {
    width: auto !important;
    height: auto;
    padding: 0;
    background: transparent;
    border: none;
    border-radius: 50%;
    box-shadow: none;
    line-height: 0;

    & div {
      display: none;
    }
  }

  &-primary {
    padding: 5px 34px;
    background: $color-bluetiful;
    border-color: $color-bluetiful;
    font-family: $fontFamily-Roboto-Black;
    color: $color-white;

    &:hover {
      color: $color-white !important;
      border-color: $color-bluetiful !important;
    }
  }

  &-gray {
    padding: 5px 34px;
    background: $color-anti-flash-white-light;
    border-color: $color-anti-flash-white-light;
    font-family: $fontFamily-Roboto-Black;
    color: $color-gunmetal;
  }

  &-bordered {
    padding: 5px 34px;
    border: 1px solid $color-bluetiful;
    font-family: $fontFamily-Roboto-Black;
    color: $color-bluetiful;
  }
}

.content {
  width: 100%;
  height: 100%;
  padding-bottom: 48px;
  display: flex;
  flex-direction: column;
  flex: 1;

  &__header {
    width: 100%;
    height: 112px;
    display: flex;
    align-items: center;
    gap: 16px;

    &-title {
      font-size: $fontSize-lg;
    }
  }

  &__container {
    width: 100%;
    height: 100%;
    display: grid;
    flex: 1;
    gap: 16px;

    &_aside {
      width: 240px;
      height: 100%;
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      background: $color-white;
      border-radius: 8px;
      box-shadow: 0px 2px 4px 0px #22292F14;

      &-title {
        font-family: $fontFamily-Roboto-Black;
        text-transform: uppercase;
      }

      &-hospital-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        color: $color-slate-gray;
      }
    }

    &_main {
      width: 100%;
      height: 100%;
      padding: 24px 16px;
      display: flex;
      flex-direction: column;
      flex: 1;
      background: $color-white;
      border-radius: 8px;
      box-shadow: 0px 2px 4px 0px #22292F14;
    }
  }
}