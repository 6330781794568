.database {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &_item {
      height: 67px;
      padding: 0 32px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $color-anti-flash-white;

      & .ant-form-item {
        width: 100%;
        margin: 0;

        & .ant-row {
          display: grid;
          grid-template-columns: 410px 1fr;

          & .ant-col {
            height: 32px;

            &:first-child {
              display: flex;
              align-items: center;

              & label {
                font-family: $fontFamily-Roboto-Regular;

                &::before,
                &::after {
                  display: none;
                }
              }
            }

            & .ant-form-item-explain-error {
              font-size: $fontSize-xsm;
            }
          }
        }
      }

      & .ant-input,
      & .ant-select,
      & .ant-input-number {
        max-width: 480px;
      }

      & .ant-select-selection-search {
        & input::-webkit-contacts-auto-fill-button {
          mask-image: none;
          background: transparent;
        }
      }
    }

    &_main {
      padding: 0;
      padding-bottom: 40px;

      &-menu {
        padding: 0 18px;
        display: flex;
        align-items: center;

        & .ant-menu {
          width: 100%;

          &-overflow {
          height: 100%;

            &-item {
              display: flex;
              align-items: center;
              font-family: $fontFamily-Roboto-Black;
            }
          }

          &-item:hover::after,
          &-item-active::after,
          &-item-selected::after {
            width: 100%;
            border-bottom-width: 1px !important;
            inset-inline: 0 !important;
          }

          &-item::after {
            transition: border-color 0.3s cubic-bezier(0, 0, 0, 0) !important;
          }

          &-title-content {
            color: $color-gunmetal;
          }
        }
      }

      &-header {
        height: 80px;
        padding: 0 16px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;

        &-hospitals {
          display: flex;
          align-items: center;
          gap: 16px;
          color: $color-slate-gray;

          & .ant-select {
            width: 293px;
          }
        }
      }
    }

    &_search {
      width: 293px;
    }

    &_footer {
      display: flex;
      align-items: center;
      gap: 24px;
    }
  }
}
