.study-card {
  &__mandatory-certification {
    min-height: 288px;
    padding: 26px 32px;
  
    &_title {
      font-family: $fontFamily-Roboto-Regular;
      margin-bottom: 12px;
    }
  
    &_item {
      margin-bottom: 18px;
      display: flex;
      align-items: center;
      gap: 24px;

      & .ant-form-item {
        margin-bottom: 0;

        & .ant-col {
          & .ant-form-item-control-input + div:nth-child(2) {
            display: none !important;
          }
        }

        &-has-error {
          & .ant-col {
            & .ant-form-item-control-input + div:nth-child(2) {
              display: block !important;
            }

            & .ant-form-item-explain {
              position: absolute;
              bottom: -18px;
              font-size: 12px;
            }
          }
        }
      }
  
      &-search {
        width: 212px !important;
      }
  
      &-select {
        width: 224px !important;
      }
    }
  }
}
