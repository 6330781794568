.facility-card {
  &__equipments {
    padding: 0 32px;

    &-header {
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
    }
  }
}

.facility-equipment-modal {
  & .ant-modal-header {
    margin-bottom: 18px;
  }
  & .ant-modal-title {
    font-family: $fontFamily-Roboto-Regular;
    font-weight: 400;
    font-size: $fontSize-lg;
  }
  &__body {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    &-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: $color-slate-gray;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}