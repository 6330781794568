.dashboard-schedule {
  &__cell {
    background: $color-white !important;

    &_title {
      height: 100%;
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &-availability {
        &--blue {
          position: relative;
          font-family: $fontFamily-Roboto-Regular !important;
          font-weight: 400 !important;
          color: $color-bluetiful;
          
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            display: block;
            border-bottom: 1px dotted $color-bluetiful;
          }

          &:hover::after {
            border: none;
          }
        }

        &--red {
          font-family: $fontFamily-Roboto-Regular !important;
          font-weight: 400 !important;
          color: $color-coral-red;
        }

        &--black {
          font-family: $fontFamily-Roboto-Regular !important;
          font-weight: 400 !important;
          font-size: $fontSize-xsm;
        }

        &-item {
          &-name {
            color: $color-slate-gray;
          }
          
          &--true {
            color: $color-kelly-green;
          }

          &--false {
            color: $color-tomato;
          }
        }
      }
    }
  }

  & .ant-table-tbody {
    & .ant-table-row {
      & .ant-table-cell {
        min-width: 152px;
        min-height: 150px;
        justify-content: space-between;
      }
    }
  }

  &__title {
    width: 240px;
  }

  &__container {
    grid-template-columns: 240px 1fr;
  }

  &__item {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-container {
      margin-bottom: 8px;
    }

    &-technician {
      margin-bottom: 6px;
      display: flex;
      align-items: flex-start;
      gap: 6px;

      & svg {
        margin-top: 4px;
      }

      &-name {
        flex: 1;
        max-width: max-content;
        min-width: min-content;
      }
    }

    &-patient {
      color: $color-slate-gray;
    }

    &_equipment-name {
      color: $color-slate-gray;

      &.bold {
        font-weight: bold;
      }
    }

    &_equipment-count {
      color: $color-kelly-green;
    }
  }
}
