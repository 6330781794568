// Color variables
$color-white: #ffffff;
$color-anti-flash-white: #eef1f4;
$color-anti-flash-white-light: #f5f7f8;
$color-ghost-white: #edf1fc;
$color-honeydew: #f1faed;
$color-cosmic-latte: #fdf9eb;
$color-snow: #fff6f4;
$color-light-slate-gray: #7c858e;
$color-slate-gray: #6c7682;
$color-silver: #bfbfbf;
$color-pale-aqua: #bacad6;
$color-gunmetal: #22292f;
$color-bluetiful: #2766f2;
$color-jordy-blue: #93B2F8;
$color-light-steel-blue: #bed1fb;
$color-glaucous: #6887cA;
$color-fern: #5bb56e;
$color-kelly-green: #68bd42;
$color-coral-red: #ff4d4f;
$color-tomato: #ff6745;

// Font family variables
$fontFamily-Roboto-Regular: 'Roboto-Regular', sans-serif;
$fontFamily-Roboto-Medium: 'Roboto-Medium', sans-serif;
$fontFamily-Roboto-Black: 'Roboto-Black', sans-serif;

// Font size variables
$fontSize-xsm: 12px;
$fontSize-sm: 14px;
$fontSize-md: 16px;
$fontSize-lg: 24px;