@import '../abstracts/variables';

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../../fonts/Roboto-Regular.ttf');
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../../fonts/Roboto-Medium.ttf');
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: 'Roboto-Black';
  src: url('../../fonts/Roboto-Black.ttf');
  font-weight: 900;
  font-style: normal
}

html {
  width: 100%;
  height: 100%;
}

body {
  min-width: 1600px;
  width: 100%;
  min-height: 100%;
  height: 100%;
  margin: 0;
  background-color: $color-anti-flash-white;
  font-family: $fontFamily-Roboto-Regular;
  font-size: $fontSize-sm;
  color: $color-gunmetal;
}

#root {
  width: 100%;
  height: 100%;
}
  
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}