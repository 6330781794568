.date-filter {
  width: 100%;
  height: 40px;
  padding: 0 12px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $color-glaucous;
  border-radius: 6px;

  &__btn {
    background: transparent;
    border: none;
    box-shadow: none;

    & div {
      display: none;
    }

    & path {
      fill: $color-white;
    }

    &-decrement {
      transform: rotate(90deg);
    }

    &-increment {
      transform: rotate(-90deg);
    }
  }

  &__container {
    position: relative;

    & path {
      fill: $color-white;
    }

    & .ant-picker {
      width: 288px;
      padding: 4px 60px;
    }
  }

  &__calendar {
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 1;

    &_cell {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.today {
        border: 1px solid $color-bluetiful;
      }
    }

    & .ant-picker {
      &-input {
        flex-direction: row-reverse;
        gap: 8px;

        & input {
          font-family: $fontFamily-Roboto-Black;
          color: $color-white;
          cursor: pointer;
        }
      }

      &-suffix {
        margin: 0;
      }
    }

    &-arrow {
      position: absolute;
      top: 50%;
      right: 68px;
      transform: translate(0, -50%)
    }
  }
}
