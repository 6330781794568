.schedule {
  & table {
    display: flex;
    flex-direction: column;
  }

  & .ant-table-cell {
    border: 0.5px solid $color-silver;
    border-collapse: collapse;
  }

  & .ant-table-thead {
    width: 100%;

    & tr {
      display: grid;
      grid-template-columns: repeat(8, 1fr);

      & .ant-table-cell {
        height: 76px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        background: $color-anti-flash-white-light;
        border-top: none;
        font-family: $fontFamily-Roboto-Black;

        &::before {
          display: none;
        }

        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  & .ant-table-tbody {
    display: flex;
    flex-direction: column;

    & .ant-table-placeholder {
      display: flex;

      & .ant-table-cell {
        width: 100%;
      }
    }

    & .ant-table-row {
      display: grid;
      grid-template-columns: repeat(8, 1fr);

      & .ant-table-cell {
        display: flex;
        flex-direction: column;
        background: $color-anti-flash-white-light;

        &:first-child {
          justify-content: center;
          border-left: none;
        }
      }
    }
  }
}
