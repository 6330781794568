.technician-credentials {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  & .ant-space {
    padding: 4px 0;
    gap: 26px !important;
  }

  &__header {
    font-size: 20px;
  }

  & .ant-form-item {
    margin-bottom: 0;
  }

  &__container {
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    & .ant-form-item {
      margin-bottom: 0;

      & .ant-col {
        & .ant-form-item-control-input + div:nth-child(2) {
          display: none !important;
        }
      }

      &-has-error {
        & .ant-col {
          & .ant-form-item-control-input + div:nth-child(2) {
            display: block !important;
          }

          & .ant-form-item-explain {
            position: absolute;
            bottom: -18px;
            font-size: 12px;
          }
        }
      }
    }

    &_validUntil-picker {
      width: 168px;
      border-color: $color-pale-aqua;

      & .ant-picker-input {
        & input {
          color: $color-slate-gray !important;
        }
      }
    }
  }
}

.technician-standard-schedule {
  padding: 0 32px;
  margin-bottom: 40px;

  & .ant-table-thead {
    & tr {
      grid-template-columns: repeat(7, 1fr);
    }
  }

  & .ant-table-tbody {
    & .ant-table-row {
      grid-template-columns: repeat(7, 1fr);

      & .ant-table-cell {
        min-width: 200px;
        min-height: 220px;
        justify-content: flex-start !important;

        &.day_off {
          background: $color-white !important;
        }
    
        &.day {
          background: $color-cosmic-latte !important;
        }
    
        &.night {
          background: $color-ghost-white !important;
        }
      }
    }
  }

  &__header {
    height: 80px;
    display: flex;
    align-items: center;
    font-family: $fontFamily-Roboto-Regular;
    font-weight: 400;
    font-size: 20px;
  }
}