.bed-schedule {
  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    font-family: $fontFamily-Roboto-Regular;
    color: $color-slate-gray;

    &_equipment {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 4px;
  
      &-0 {
        color: $color-coral-red;
      }

      &_modal {
        & .ant-modal-header {
          margin-bottom: 16px;
        }
  
        & .ant-modal-title {
          font-family: $fontFamily-Roboto-Regular;
          font-size: $fontSize-lg;
        }
  
        & .ant-modal-body {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
  
        &-item {
          display: flex;
          align-items: center;
          gap: 16px;
        }
  
        &-footer {
          display: flex;
          align-items: center;
          gap: 16px;
        }
      }
    }

    &_tooltip {
      display: none;
      position: absolute;
      bottom: 207px;
      left: 50%;
      width: 100%;
      transform: translate(-50%, 0);
      padding: 4px 8px;
      background: $color-white;
      border: 1px solid $color-coral-red;
      border-radius: 6px;
      color: $color-coral-red;
    }

    .cell-error:hover &_tooltip {
      display: block;
    }
  }

  &__counter {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $color-gunmetal;
      
      &-0 {
        color: $color-coral-red;
      }

      &-total::before {
        content: '/';
      }
    }

    &_actions {
      display: none;
      align-items: center;
      gap: 8px;
    }

    .bed-schedule__item:hover &_actions {
      display: flex;
    }
  }
}
