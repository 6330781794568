.header {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  background: $color-white;
  box-shadow: 0px 2px 4px 0px rgba(34, 41, 47, 0.08);

  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__logo {
      font-size: $fontSize-lg;
    }

    &__menu {
      width: 100%;

      & * {
        font-family: $fontFamily-Roboto-Black;
        text-transform: uppercase;
      }

      & ul {
        justify-content: center;
      }

      & .ant-menu-item {
        & a {
          transition: none;
        }
        
        &::after {
          display: none;
        }

        &-selected,
        &-active .ant-menu-title-content {
            color: $color-bluetiful !important;
        }
      }
    }

    &__profile {
      & * {
        color: $color-light-slate-gray;
      }

      & svg {
        width: 24px;
        height: 24px;
      }

      & .ant-dropdown-trigger {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      &_user-icon {
        border-radius: 50%;
      }
    }
  }
}