@import '../abstracts/variables';

.ant-layout {
  width: 100%;
  min-height: 100vh;
  background: none;
  font-family: $fontFamily-Roboto-Regular, sans-serif;
}

.ant-menu {
  border: none;
}

.ant-radio {
  &-group {
    & .ant-space {
      gap: 16px !important;
    }
  }

  &-wrapper {
    color: $color-slate-gray;

    &-checked {
      color: $color-gunmetal;
    }
  }

  &-checked {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: $color-jordy-blue;
    }
  }
}

.ant-select {
  width: 100%;

  &-selector {
    border-color: $color-pale-aqua !important;
  }

  &-selection-item {
    color: $color-slate-gray;
  }

  &-multiple {
    & .ant-select-selection-item {
      background: $color-glaucous;
      color: $color-white;
    }

    & .ant-select-selection-item-remove {
      display: none;
    }
  }

  &-status-error {
    & .ant-select-selector {
      border-color: $color-coral-red !important;
    }
  }
}

.ant-picker {
  &-panel-container {
    background: $color-white !important;
    border-radius: 6px !important;
    box-shadow: 0px 2px 15px 0px #22292f1a !important;
  }

  &-header {
    border: none !important;

    &-super-prev-btn,
    &-super-next-btn {
      display: none;
    }

    &-view {
      font-family: $fontFamily-Roboto-Regular;
      font-weight: normal !important;
    }
  }

  &-content {
    width: 100% !important;
  }

  &-body {
    padding: 8px 0 0 !important;

    & thead {
      & th[aria-label='empty cell'] {
        display: none;
      }

      & th {
        font-family: $fontFamily-Roboto-Black;
        color: $color-slate-gray !important;
      }
    }

    & tbody {
      & .ant-picker-cell-week {
        display: none;
      }

      & .ant-picker-cell {
        width: 48px;
        height: 40px;
        padding: 0;
        border: 1px solid #f0f0f0;
      }

      & tr {
        & .ant-picker-cell:nth-child(1),
        & .ant-picker-cell:nth-child(2) {
          border-left: none !important;
        }

        & .ant-picker-cell:last-child {
          border-right: none !important;
        }

        &:last-of-type {
          & .ant-picker-cell {
            border-bottom: none !important;
          }
        }

        &.ant-picker-week-panel-row {
          &-selected,
          &:hover {
            & .ant-picker-cell::before {
              top: 0;
              height: 40px;
              transform: none;
            }
  
            & .ant-picker-cell:last-child::before {
              border-radius: 0;
            }
          }

          &-selected {
            & .ant-picker-cell {
              background: #eff3fc;
            }

            & .ant-picker-cell:hover {
              background: $color-light-steel-blue;
            }

            & .ant-picker-cell::before {
              background: transparent;
            }

            & .ant-picker-cell-inner {
              color: $color-gunmetal !important;
            }
          }
        }

        & .ant-picker-cell {
          &-today,
          &-selected,
          &:hover {
            & .ant-picker-cell-inner {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 0 !important;

              &::before {
                border-radius: 0 !important;
              }
            }
          }

          &-selected {
            & .ant-picker-cell-inner {
              background: #eff3fc !important;
              color: $color-gunmetal !important;
            }
          }
        }

        & .ant-picker-cell {
          &:hover {
            background: $color-light-steel-blue;
          }
        }
      }

      & .ant-picker-cell-disabled::before {
        width: 100%;
        height: 100%;
      }
    }
  }

  &-footer {
    display: none;
  }
}

.ant-tooltip {
  &-arrow::before,
  &-arrow::after {
    display: none;
  }

  &-inner {
    background: $color-white !important;
    border: 1px solid $color-pale-aqua;
    color: $color-gunmetal !important;
    box-shadow: none !important;
  }
}

.ant-switch {
  &-checked {
    background: $color-bluetiful !important;
  }
}

.ant-input {
  border-color: $color-pale-aqua;
  color: $color-slate-gray;

  &-number {
    width: 100%;
    border-color: $color-pale-aqua;

    &-handler-wrap {
      display: none !important;
    }

    &-input {
      color: $color-slate-gray !important;
    }
  }

  &-wrapper {
    & .ant-input {
      border-left: none;
      padding-left: 0;

      &:focus,
      &:hover {
        box-shadow: none;
        border-color: $color-pale-aqua;
      }
    }
  }

  &-group-addon {
    border-color: $color-pale-aqua !important;
    background: transparent !important;
    color: $color-slate-gray !important;
  }
}

.ant-checkbox {
  &-group {
    & .ant-space {
      gap: 16px !important;
    }
  }

  &-wrapper {
    color: $color-slate-gray;

    &-checked {
      color: $color-gunmetal;
    }
  }

  &-checked::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 22px;
    height: 22px;
    border-radius: 6px;
    background: $color-jordy-blue;
  }
}

.ant-layout-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ant-table {
  &-cell {
    &.cell-green {
      background: $color-honeydew !important;
    }

    &.cell-red {
      background: $color-snow !important;
    }

    &.cell-yellow {
      background: $color-cosmic-latte !important;
    }

    &.cell-error {
      border: 1px solid $color-coral-red !important;
    }
  }
}
