.database-table {
  & thead {
    & th, td {
      background: $color-anti-flash-white !important;
      font-family: $fontFamily-Roboto-Black;
      color: $color-slate-gray !important;
      text-transform: uppercase;
    }

    & .ant-table-column-sorters {
      justify-content: flex-start;

      & span:first-child {
        flex: none;
      }
    }

    & .ant-table-column-sorter-inner {
      transform: rotate(180deg);
    }
  }

  & .ant-table-cell {
    border-radius: 0 !important;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    & svg {
      width: 20px;
      height: 20px;
    }

    & path {
      fill: $color-silver;
    }
  }
}
